import React, { useState } from "react";
import { MissionControlTiles } from "./mission-control-tiles";
import { Tile } from "@transfr-inc/dashboard-components";

import { Button, ToggleButton } from "@transfr-inc/dashboard-components/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

import clsx from "clsx";

import "./mission-control-container.scss";

export const MissionControlContainer = ({
  classroom,
  users,
  fullScreen,
  setFullScreen,
  missionControlDataArray,
  loading,
  showZero,
  isConnected,
  showLiveFeed,
  onToggleLiveFeed,
  imageData,
}) => {
  const [spotlightTile, setSpotlightTile] = useState();
  const onFullScreenHandler = () => {
    setFullScreen(true);
  };

  return (
    <>
      <div className="tiles-main-container">
        <div
          className={clsx(
            "mission-control-header",
            fullScreen && "full-screen-header"
          )}
        >
          {fullScreen ? (
            <div className="classroom-info">
              <div className="classroom-name">{classroom?.name}</div>
              <div className="mission-control-title-container">
                <div className="mission-control-title">Mission Control</div>
                <div className="mission-control-badge">
                  {missionControlDataArray?.length}
                </div>
              </div>
            </div>
          ) : (
            <Button onClick={onFullScreenHandler} disabled={false}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Full Screen
            </Button>
          )}
          <div className="toggle-live-feed-container">
            <div className="toggle-live-feed">
              <div className="description">Live Feed</div>
              <ToggleButton
                onChange={onToggleLiveFeed}
                value={showLiveFeed}
                disabled={!isConnected}
              />
            </div>
            {fullScreen && (
              <Button
                icon={["fa-regular", "xmark"]}
                onClick={() => setFullScreen()}
              ></Button>
            )}
          </div>
        </div>
        <div className={clsx("tiles-body", fullScreen && "full-screen-body")}>
          <div
            className={clsx(
              "mission-control-main-container",
              spotlightTile && "spotlight-mode"
            )}
          >
            {spotlightTile && (
              <div className={"spotlight-tile"}>
                <Tile {...spotlightTile} />
              </div>
            )}
            <div className="non-spotlight-tiles">
              <MissionControlTiles
                classroom={classroom}
                users={users}
                setSpotlightTile={setSpotlightTile}
                spotlightTile={spotlightTile}
                setFullScreen={setFullScreen}
                missionControlDataArray={missionControlDataArray}
                loading={loading}
                showZero={showZero}
                isConnected={isConnected}
                showLiveFeed={showLiveFeed}
                onToggleLiveFeed={onToggleLiveFeed}
                fullScreen={fullScreen}
                imageData={imageData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
