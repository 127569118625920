import React from "react";

import {
  FullScreenModal,
  FullScreenModalBody,
} from "@transfr-inc/dashboard-components";

import "./mission-control-full-screen.modal.scss";

export function MissionControlFullScreenModal({ open, onClose, children }) {
  return (
    <FullScreenModal
      open={open}
      className="mission-control-spotlight-modal"
      onClose={onClose}
      trueFullScreen
    >
      <FullScreenModalBody className="mission-control-spotlight-body">
        {children}
      </FullScreenModalBody>
    </FullScreenModal>
  );
}
