import clsx from "clsx";
import React from "react";

import container from "../../../container";

import "./skill.icon.scss";

export const SkillIcon = ({ skill, className }) => {
  const { trekService } = container;
  const { id, name, categoryId, subcategoryId } = skill;

  const skillImageUrl = trekService.getSkillImage(id);
  const subcategoryImageUrl = trekService.getSkillImage(
    `subcategories/${subcategoryId}`
  );
  const categoryImageUrl = trekService.getSkillImage(
    `categories/${categoryId}`
  );
  const defaultImageUrl = trekService.getSkillImage("default");

  const handleError = ({ target }) => {
    if (target.src.endsWith(skillImageUrl)) {
      // if not specefic icon then try subcategory icon
      target.src = subcategoryImageUrl;
    } else if (target.src.endsWith(subcategoryImageUrl)) {
      // If not subcategory icon then try category icon
      target.src = categoryImageUrl;
    } else {
      // if not category icon then go default icon - rare case
      target.src = defaultImageUrl;
    }
  };

  return (
    <div className={clsx("skill-tile", className)}>
      <img
        className="skill-tile-image"
        src={skillImageUrl}
        alt={`${name} icon`}
        onError={handleError}
      />
      <div className="skill-tile-title">{name}</div>
    </div>
  );
};
